import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import 'react-phone-number-input/style.css';

import style from './style.module.scss';

import { StatusSteps, TypeForm } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';
import Form from './From';
import Check from '../../../components/ux/Inputs/Check';

import img_firepalse from '../../img/galery/_1.jpg';
import sofa_firepalse from '../../img/galery/_2.jpg';
import table_firepalse from '../../img/galery/_3.jpg';


type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Phone: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData, Send } = useForm();

  const [errorForms, setErrorForms] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;

    if (!form.phone) {
      setErrorForms(() => !!!form.phone);
      isNext = false;
    }
    if (String(form.phone).length !== 13) {
      setErrorForms(() => !!!form.phone);
      isNext = false;
    }

    else {
      Send(form);
      setSend(true);
    }

    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      phone: isNext,
    }));

    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.phone, form.connect, errorForms]);

  useEffect(() => {
    const sum = form.height?.prise || 0;

    updateData('prise', sum)
  }, [form.height])


  if (send) {
    return <Sended />
  }

  return (
    <div className={style.container}>
      <div className={style.box}>
        <p className={style.title}>Звʼяжимось відразу як опрацюємо попередні замавлення)</p>
        <Form updateData={updateData} form={form} />
        <div className={style.priseBlock}>
          {form.prise ?
            <>
              Забрати лампу за <span>{form.prise} грн.</span>
              {form.bonus ? <span className={style.oldPrise}>{form.prise + 300} грн.</span> : ''}
            </>
            : ''
          }
        </div>
      </div>
    </div>
  );
};

export default Phone;


const Sended: FC = () => {
  return (
    <div className={style.container}>
      <div className={style.box}>
        <h2>Я наберу вас відразу, як опрацюю минулі замовлення</h2>
        <h4>Товари які можуть сподобатись</h4>
        <div className={style.boxPhoto}>
          {arePhoto()}
        </div>
      </div>
    </div>
  );
}

type PhotoArrType = {
  id: number;
  value: string;
  name: string;
  img: string;
};

const PhotoArr: PhotoArrType[] = [
  {
    id: 1,
    value: 'https://tochnoye.com/biokamin-beton-marmur/',
    name: 'Біокамін',
    img: img_firepalse,
  },
  {
    id: 2,
    value: 'https://tochnoye.com/zhurnalnyi-stolyk-white-stone/',
    name: 'Журнальний Столик',
    img: sofa_firepalse,
  },
  {
    id: 3,
    value: 'https://tochnoye.com/dyvan-loft-wls-d13-lak-zhovtyi/',
    name: 'Диван лофт',
    img: table_firepalse,
  }]

const arePhoto = () => {
  return PhotoArr.map((item: any) => {
    return (
      <div
        className={style.check}
        key={item.id}
        onClick={() => window.location.replace(item.value)}
      >
        <div className={style.imgBox}>
          <img src={item.img} alt="" />
        </div>

        <Check
          value={item.value}
          name="color"
          title={item.name}
          className={style.checkBox}
        />
      </div>
    );
  });
}