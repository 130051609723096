import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import style from './style.module.scss';

import { StatusSteps } from '../../type/form';
import { title } from 'process';

const list = [
  {
    title: '💰 Різновиди ',
    describe: [
      '2 типи основ: кругова та кутова',
      '2 кольори: білий та чорний',
      '4 висоти лам: 50см., 100см., 150см., 200см.',
    ],
  },
  {
    title: "📦 Комплектація",
    describe: [
      "Світлова частина",
      "Ніжка тримач",
      "Кабель з виходом USB",
      "Блок живлення",
      "Пульт керування",
    ],
  },
  {
    title: '📱 Керування',
    describe: [
      'Зручне керування освітленням просто за допомогою пульта дистанційного керування, що дозволяє змінювати колір, яскравість та ефекти зручно зі свого місця',
      'Також, можна використовувати власний смартфон в якості пулька керування, використовуючи застосунок на телефон'
    ],
  },
  {
    title: '🔥 Особливості',
    describe: [
      'З 16 мільйонами кольорів в вашому розпорядженні, ви можете легко налаштувати освітлення відповідно до вашого настрою, події чи сезону.',
      'Лампа може працювати від павербанка або ноутбука, що робить її мобільно.'
    ],
  },
];

type Props = {
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Characteristic: FC<Props> = ({ setStatusSteps }) => {
  useEffect(() => {
    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      fireplase: true,
    }));
  }, []);

  const BlockList = () => {
    return list.map((item, id) => {
      const describe = item.describe.map(text => (
        <span key={text}>{text}</span>
      ));

      return (
        <div className={style.item}>
          <p>{item.title}</p>
          {describe}
        </div>
      );
    });
  };

  return <div className={style.container}>{BlockList()}</div>;
};

export default Characteristic;
