import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import style from './style.module.scss';

import { StatusSteps } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';

type WhyArrType = {
  id: number;
  value: string;
  name: string;
  checked?: boolean | undefined;
};

const WhyArr: WhyArrType[] = [
  {
    id: 1,
    value: 'new',
    name: '🎁 Зробити подарунок',
    checked: false,
  },
  {
    id: 2,
    value: 'need',
    name: '🎬 Задній фон для відео',
    checked: false,
  },
  {
    id: 3,
    value: 'cool',
    name: '🛋️ Мати настільну ламку',
    checked: false,
  },
  {
    id: 4,
    value: 'light',
    name: '😍 Замість основного світла',
    checked: false,
  },
];

type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Why: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData } = useForm();
  const [errorForms, setErrorForms] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;
    if (!form.why) {
      setErrorForms(() => !!!form.why);
      isNext = false;
    }

    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      why: isNext,
    }));
    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.why, errorForms]);

  const areWhy = useCallback(() => {
    return WhyArr.map((item: WhyArrType) => {
      return (
        <div
          className={[style.card, form.why === item.name && style.active].join(
            ' ',
          )}
          key={item.id}
          onClick={() => updateData('why', item.name)}
        >
          <div className={style.title}>{item.name}</div>
        </div>
      );
    });
  }, [form.why]);

  return <div className={style.container}>{areWhy()}</div>;
};

export default Why;
