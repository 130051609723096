import React, { FC, useCallback } from 'react';

import style from './style.module.scss';
import logo_img from '../../../../../assets/img/logo/logo.svg'

type Props = {
  title: string;
  contentSteps: {
    name: string;
    step: number;
    status: boolean;
  }[];
  step: number;
  setStep: (step: number) => void;
};

const Header: FC<Props> = ({ contentSteps, title, step, setStep }) => {
  const listBox = useCallback(() => {
    return contentSteps.map(el => {
      return (
        <li
          key={el.name}
          className={[
            step === el.step && style.active,
            style.boxSteps,
            el.status && style.done,
          ].join(' ')}
          onClick={() => setStep(el.step)}
        >
          <span className={style.step}>{el.name}</span>
        </li>
      );
    });
  }, [step]);

  return (
    <div className={style.header}>
      <h2 className={style.title}>
        <img src={logo_img} alt="logo" />

        <span>
          {title}
        </span>
      </h2>
      <div className={style.containerHeader}>
        <ul className={style.box}>{listBox()}</ul>
      </div>

      {/* <Button
        text="Перейти в магазин"
        active={() => console.log(1)}
        quiz
        transparent
        className={style.btn}
      /> */}
    </div>
  );
};

export default Header;
