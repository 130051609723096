import React, { FC, useState } from 'react';

import { DefaultPropsInputPhone } from '../type';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { ReactComponent as Error } from '../../../../assets/svg/status/error.svg';

import styles from '../styles.module.scss';

const Phone: FC<DefaultPropsInputPhone> = ({
  placeholder,
  value,
  setValue,
  disabled,
  title = null,
  necessary,
  error,
  className,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [_value, _setValue] = useState(value);

  const update = (e: any) => {
    _setValue(e);
    setValue(e);
  };

  return (
    <div className={[styles.container, title && styles.withTitle, className && className].join(' ')}>
      {title && (
        <span className={styles.title}>
          {title} {necessary && <span className={styles.necessary}>*</span>}
        </span>
      )}
      <label
        className={
          [
            focus && styles.active,
            disabled && styles.disabled,
            error && styles.error,
            error && styles.box,
          ].join(' ') || ''
        }
      >
        <PhoneInput
          placeholder={placeholder}
          value={_value}
          country="UA"
          defaultCountry="UA"
          className={styles.input}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          // @ts-ignore
          onChange={update}
        />

        {error && <span className={styles.flexCenter}>{<Error />}</span>}
      </label>
      {error && <p className={styles.descriptionError}>{error}</p>}
    </div>
  );
};

export default Phone;
