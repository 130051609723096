import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import style from './style.module.scss';
import { useModal } from '../../../app/context/modal';
import {
  InputPhone,
  InputRadio,
  InputSelect,
} from '../../../components/ux/Inputs';
import { TypeForm, updateDataType } from '../../type/form';

export type PropsInfo = {
  lastNameValue: any;
};

type RadioType = {
  select: SelectTypeArr | null;
  arr: SelectTypeArr[];
  upData: updateDataType;
  form: TypeForm;
  update: string;
};

type SelectType = {
  value: string;
  title: string;
  describe: string;
  arr: SelectTypeArr[];
  select: SelectTypeArr | null;
  update: string;
  upData: updateDataType;
};

type SelectTypeArr = { value: string; prise: number };

type OpenModalType = (
  title: string,
  description: string,
  arr: SelectTypeArr[],
  select: SelectTypeArr | null,
  update: string,
  upData: updateDataType,
) => void;

type FormType = {
  updateData: updateDataType;
  form: TypeForm;
};

const RadiosModal: FC<RadioType> = ({ arr, select, upData, update }) => {
  const [isSelect, setIsSelect] = useState(select);

  const Change = (item: SelectTypeArr) => {
    upData(update, item);
    setIsSelect(item);
  };

  const areBusiness = useMemo(
    () =>
      arr.map(item => (
        <InputRadio
          key={item.value}
          value={item.value}
          name="value"
          title={item.value}
          setValue={() => Change(item)}
          checked={item.value === isSelect?.value}
        />
      )),
    [isSelect, update, select, upData],
  );

  return <div className={style.boxSelect}>{areBusiness}</div>;
};

const colors: SelectTypeArr[] = [
  { value: 'Білий', prise: 0 },
  { value: 'Чорний', prise: 0 },
];
const typeBottom: SelectTypeArr[] = [
  { value: 'Кутовий', prise: 0 },
  { value: 'Круговий', prise: 0 },
];
const heigth: SelectTypeArr[] = [
  { value: '50см. 1450грн.', prise: 1450 },
  { value: '100см. 1650грн.', prise: 1650 },
  { value: '150см. 1850грн.', prise: 1850 },
  { value: '200см. 1950грн.', prise: 1950 },
];

export const connect: SelectTypeArr[] = [
  { value: 'Дзвінок', prise: 0 },
  { value: 'Telegram', prise: 0 },
  { value: 'Viber', prise: 0 },
  { value: 'Whatsapp', prise: 0 },
];


const height_whitout: SelectTypeArr[] = [
  { value: '50см. 1750грн.', prise: 1750 },
  { value: '100см. 1950грн.', prise: 1950 },
  { value: '150см. 2150грн.', prise: 2150 },
  { value: '200см. 2250грн.', prise: 2250 },
];

const Form: FC<FormType> = ({ updateData, form }) => {
  const modal = useModal();

  const openModal: OpenModalType = (
    title,
    description,
    arr,
    select,
    update,
    upData,
  ) => {
    modal?.addModal({
      modals: {
        title,
        description,
        size: 'small',
      },
      Content: RadiosModal,
      props: { arr, select, upData, form, update },
      type: 'icon',
    });
  };

  const Select: FC<SelectType> = ({
    value,
    title,
    describe,
    arr,
    select,
    update,
    upData,
  }) => (
    <InputSelect
      value={value}
      setValue={() => openModal(title, describe, arr, select, update, upData)}
      title={title}
      className={style.selectBox}
    />
  );

  return (
    <div className={style.box}>
      <Select
        value={form?.height?.value || 'Обрати'}
        title="Ціни та розмір"
        describe="Ціна залежить від висоти"
        arr={form.bonus ? heigth : height_whitout}
        select={form?.height}
        update="height"
        upData={updateData}
      />

      <InputPhone
        value={(form.phone && String(form.phone)) || undefined}
        setValue={(value: string) => updateData('phone', value)}
        placeholder={'099...'}
        title={'Номер телефону'}
        className={style.selectBox}
      />

      <Select
        value={form?.connect?.value || 'Обрати варіант'}
        title="Як звʼязатись?"
        describe="Ми напишемо або зателефонуємо"
        arr={connect}
        select={form?.connect}
        update="connect"
        upData={updateData}
      />
    </div>
  );
};

export default Form;


