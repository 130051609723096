import React, {
  ReactNode,
  FC,
  createContext,
  useState,
  useContext,
} from 'react';

import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';

import { ContextForms, TypeForm, updateDataType } from '../type/form';

import { connect } from '../modules/Phone/From';

type Props = {
  children: ReactNode;
};

export const FromContext = createContext<ContextForms | null>(null);

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'JWT fefege...',
  'Access-Control-Allow-Origin': '*',
};

const FormProvider: FC<Props> = ({ children }) => {
  const [form, setForm] = useState<TypeForm>({
    name: '',
    why: '',
    color: null,
    phone: null,
    stand: null,
    height: null,
    prise: 0,
    bonus: null,
    connect: connect[0],
  });

  const updateData: updateDataType = (update, value) => {
    setForm((prev: TypeForm) => ({
      ...prev,
      [update]: value,
    }));
  };

  const Send = async (data: TypeForm) => {
    ReactPixel.track('Lead', {
      currency: 'USD',
      value: 14.5,
    });

    const sendDaata = {
      name: form.name || 'Пусто',
      phone: form.phone || 'Пусто',
      color: form.color?.value || 'Пусто',
      hieght: form.height?.value || 'Пусто',
      stand: form.stand?.value || 'Пусто',
      prise: form.prise || '0',
      why: form.why || '',
      connect: form.connect?.value || 'Телефонувати'
    };

    console.log(form.connect)

    await axios
      .post('./mailer/Mail.php', sendDaata, { headers })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const contextValue: ContextForms = {
    form,
    setForm,
    updateData,
    Send,
  };

  return (
    <FromContext.Provider value={contextValue}>{children}</FromContext.Provider>
  );
};

const useForm = () => {
  const context = useContext(FromContext);

  if (!context) throw new Error('useForm must be used within a FromContext');

  return context;
};

export { FormProvider, useForm };
