import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import style from './style.module.scss';

import { StatusSteps } from '../../type/form';
import { SendFormType } from '../../../components/module/Quiz';
import { useForm } from '../../context/form';

const bonuses = [
  {
    id: 1,
    name: '🏷️ Знижка -5%',
    describe: '-5% на ціну будь-якої лампи.',
  },
  {
    id: 2,
    name: '⛽️ Доставка',
    describe: 'Безкоштовна доставка в будь-яку точку України',
  },
  {
    id: 3,
    name: '🏷️ Знижка -15%',
    describe: '-15% на ціну будь-якої лампи.',
  },
  {
    id: 4,
    name: '😕 Нічого',
    describe: 'Уви бонусу немає, повезе в коханні)',
  },
];

const bonus_is = {
  name: '🔥 Стара ціна',
  describe: 'Мої вітання, тобі повезло вибрати старі ціни на лампи, це максимальний бонус.',
}

type Props = {
  sendForm: SendFormType;
  setStatusSteps: Dispatch<SetStateAction<StatusSteps>>;
};

const Bonus: FC<Props> = ({ sendForm, setStatusSteps }) => {
  const { form, updateData } = useForm();

  const [errorForms, setErrorForms] = useState<boolean>(false);

  const checkFrom = (): boolean => {
    let isNext = true;
    if (!form.bonus) {
      setErrorForms(() => !!!form.bonus);
      isNext = false;
    }

    setStatusSteps((prev: StatusSteps) => ({
      ...prev,
      bonus: isNext,
    }));
    return isNext;
  };

  useEffect(() => {
    sendForm(() => checkFrom);
  }, [form.bonus, errorForms]);

  const allCards = useCallback(() => {
    if (form.bonus) {
      return bonuses.map(el => (
        <div className={[style.card, style.active].join(' ')} key={el.id}>
          <div className={style.front}>Натисни тут 2</div>
          <div
            className={[style.back, form.bonus == el.id && style.bonus].join(
              ' ',
            )}
          >
            <p> {form.bonus == el.id ? bonus_is.name : el.name}</p>
            <span>
              {form.bonus == el.id
                ? bonus_is.describe
                : el.describe}
            </span>
          </div>
        </div>
      ));
    }

    return bonuses.map(el => (
      <div
        className={style.card}
        key={el.id}
        onClick={() => updateData('bonus', el.id)}
      >
        <div className={style.front}>Натисни тут 🎁 </div>
        <div className={[style.back, style.bonus].join(' ')}></div>
      </div>
    ));
  }, [form.bonus]);

  return <div className={style.container}>{allCards()}</div>;
};

export default Bonus;
