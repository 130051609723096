import React, { useCallback, useEffect, useState } from 'react';

import style from './style.module.scss';

import Quiz, { SendFormType } from '../components/module/Quiz';
import { StatusSteps } from './type/form';

import Name from './modules/Name';
import Color from './modules/Color';
import Bonus from './modules/Bonus';
import Characteristic from './modules/Characteristic';
import Phone from './modules/Phone';
import Why from './modules/Why';

import { FormProvider } from './context/form';

import PixelFB from './pixel/fb';

const QuizPage = () => {
  const [statusSteps, setStatusSteps] = useState<StatusSteps>({
    name: false,
    color: false,
    phone: false,
    fireplase: false,
    bonus: false,
    why: false
  });

  useEffect(() => {
    PixelFB.init();
    PixelFB.pageView();
  }, []);

  const contentSteps = useCallback(
    () => [
      {
        name: 'Привіт',
        step: 1,
        status: statusSteps.name,
        content: {
          title: 'Привіт, це інтернет магазин "Точно Є"',
          descriptions: 'Якщо готовий дізнатись про лампу та хоч купити, сміливо пиши своє імʼя і ми починаємо',
          Form: (sendForm: SendFormType) => (
            <Name sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Чому',
        step: 2,
        status: statusSteps.why,
        content: {
          title: 'Для чого Вам лампа?',
          Form: (sendForm: any) => (
            <Why setStatusSteps={setStatusSteps} sendForm={sendForm} />
          ),
        },
      },


      {
        name: 'Характеристка',
        step: 3,
        status: statusSteps.fireplase,
        content: {
          title: '2 слова про LED Лампу',
          descriptions: '90% не знають про це',
          Form: (sendForm: any) => (
            <Characteristic setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Галерея',
        step: 4,
        status: statusSteps.color,
        content: {
          title: 'Ось як лампа вписується в кімнату',
          descriptions:
            '16 міліонів кольорів',
          Form: (sendForm: SendFormType) => (
            <Color setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Бонус',
        step: 5,
        status: statusSteps.fireplase,
        content: {
          title: 'Обери найкращий подарунок',
          descriptions: '4 різни бонуси, нехай щастить',
          Form: (sendForm: any) => (
            <Bonus sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
      {
        name: 'Замовити',
        step: 6,
        status: statusSteps.phone,
        content: {
          title: 'Тут можна оформити замовлення',
          descriptions: statusSteps.bonus
            ? '🎁 Твій бонус - це старці ціни на LED лапу'
            : '',
          Form: (sendForm: SendFormType) => (
            <Phone sendForm={sendForm} setStatusSteps={setStatusSteps} />
          ),
        },
      },
    ],
    [statusSteps],
  );

  return (
    <div className={style.container}>
      <div className={style.video}></div>
      <Quiz title="Точнно Є" contentSteps={contentSteps()} />
    </div>
  );
};

export default () => (
  <FormProvider>
    <QuizPage />
  </FormProvider>
);
